<template>
  <div class="error">
    <div style="font-size: 38px;">
      目前轻松小镇提供移动端
      请用移动端设备重新打开本页面
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  created () {
    localStorage.clear()
  }
}
</script>

<style scoped lang="scss">
.error{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
